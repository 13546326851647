import { useEffect, useState } from 'react'
import { Wrapper } from './styles'
import { getADUsersByRep } from '../../services/clientContext/ClientsApi'
import { PermissionViewProps } from './IPermissions'
import { useLazyQuery } from '@apollo/client'
import { GET_PERMISSIONS_BY_ID } from '../../services/graphQl/PermissionsGraphQL'
import Table from '../../components/table/Table'
import GraphQLLoader from '../loader/GraphQLLoader'
import NoDataFound from '../noDataFound/NoDataFound'

const PermissionView = ({ leadId }: PermissionViewProps) => {
  const permissionColumns = [
    {
      Header: 'Type',
      accessor: 'typelabel',
      width: '100px',
    },
    {
      Header: 'Code',
      accessor: 'code',
      width: '100px',
    },
    {
      Header: 'Advisors',
      accessor: 'users',
      width: 'auto',
    },
  ]
  const [permissionData, setPermissionData] = useState<any[]>()
  const [tableData, setTableData] = useState<any[]>([])
  const [getPermissions, { loading }] = useLazyQuery<any>(GET_PERMISSIONS_BY_ID)

  const repCodeType = [
    {
      label: 'Primary Owner',
      value: 'Primary Owner',
    },
    {
      label: 'Rep Code',
      value: 'Rep Code',
    },
    {
      label: 'Branch Code',
      value: 'Branch Code',
    },
    {
      label: 'Office Code',
      value: 'Office Code',
    },
  ]

  useEffect(() => {
    getPermissions({ variables: { leadid: leadId } })
      .then((res: any[any]) => {
        if (res?.data) {
          const permissions: any = []
          res.data.lead_permissions.map((permission: any) => {
            permissions.push({
              typelabel: repCodeType.filter((t) => {
                return t.value == permission.type
              })[0]?.label,
              code: permission.repcode,
            })
          })
          setPermissionData([...permissions])
        }
      })
      .catch(() => console.log('error'))
  }, [])

  const combineTableData = () => {
    permissionData?.map((permission: any) => {
      getADUsersByRep({ repCode: permission.code })
        .then((resp: any[any]) => {
          const users: any[] = []
          resp?.data.map((user: any) => {
            users.push(`${user.advisorFullName}(${user.adUserName})`)
          })
          setTableData((prev: any) => [
            ...prev,
            { ...permission, users: users.join(', ') },
          ])
        })
        .catch(() => console.log('error'))
    })
  }

  useEffect(() => {
    combineTableData()
  }, [permissionData])

  return (
    <Wrapper>
      {loading ? (
        <GraphQLLoader />
      ) : tableData.length > 0 ? (
        <Table
          wrapData={true}
          source={'PermissionData'}
          tableColumns={permissionColumns}
          allData={tableData ?? []}
        />
      ) : (
        !loading && tableData?.length === 0 && <NoDataFound />
      )}
    </Wrapper>
  )
}

export default PermissionView
