import { useLazyQuery, useMutation } from '@apollo/client'
import { CreateEventComponent } from 'note-widget'
//import { CreateEventComponent } from '../../noteWidget'
import { useContext, useEffect, useState } from 'react'
import Table from '../../components/table/Table'
import {
  GET_EVENTS,
  GET_EVENTS_REPCODE_LIST,
  GET_NOTES_BOOK,
  GET_EVENTS_COUNTS,
  UPDATE_EVENT_STATUS,
} from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import { ContentItemWrapper, Wrapper } from '../../shared/GlobalStyle'
import { leadColumnData } from './EventData'
import { vars } from '../../assets/utils/CommonFunctions'
import {
  leadsData,
  partiesData,
  subTypeData,
  typeData,
} from '../../reducers/leadPartySlice'
import { AuthContext } from '../../services/AuthContext'
import appConfig from '../../settings/appConfig'
import { useAppSelector } from '../../store/store'
import Loader from '../../composites/loader/Loader'
import EditActionContext from '../../services/editActionsContext/EditActionsContext'
import { useSelector } from 'react-redux'
import FilterContext from '../../services/filterContext/FilterContext'

const Event = () => {
  const { user } = useContext(UserContext)
  const { loadingEdit } = useContext(EditActionContext)
  const [showNewEvents, setNewEvents] = useState<boolean>(false)
  const [eventFilter, setEventFilter] = useState({
    id: '1',
    value: 'All Events',
  })
  const [eventContent, setEventContent] = useState<any[]>([])
  const leadsDetails = useAppSelector(leadsData)
  const partyDetails = useAppSelector(partiesData)
  const { accessToken } = useContext(AuthContext)
  const [selectedTab] = useState('Created By')
  const [repCodeData, setRepCodeData] = useState<any[]>([])
  const [summaryFilters, setSummaryFilters] = useState<
    { id: string; value: string | string[] | undefined | null }[]
  >([])
  const [updateMeeting] = useMutation(UPDATE_EVENT_STATUS)
  const typesData = useSelector(typeData)
  const subTypesData = useSelector(subTypeData)
  const [allTypes, setAllTypes] = useState<any[]>([])
  const [subTypes, setSubTypes] = useState<any[]>([])
  const {
    setInteractionsPayload,
    filterPayload,
    setFilterPayload,
    setScrollPosition,
    resetTableFilter,
  } = useContext(FilterContext)
  const [getEvent, { loading: eventsLoadingCreatedBy, data: eventData }] =
    useLazyQuery(selectedTab == 'Created By' ? GET_EVENTS : GET_NOTES_BOOK, {
      variables:
        selectedTab == 'Created By'
          ? {
              payload: setInteractionsPayload('event'),
              limit: vars.InitRecordCount,
              offset: filterPayload.offset,
            }
          : {
              userName: user.username,
              tranType: 3,
            },
      fetchPolicy: 'no-cache',
    })
  const [
    getEventsCreatedByCount,
    { data: eventDataCreatedByCount, loading: loadingCreatedByEventsCount },
  ] = useLazyQuery(GET_EVENTS_COUNTS, {
    variables: {
      payload: setInteractionsPayload('event'),
    },
    fetchPolicy: 'no-cache',
  })

  const fetchData = (scrollHeight: number) => {
    if (
      !eventsLoadingCreatedBy &&
      !loadingCreatedByEventsCount &&
      filterPayload.offset + vars.InitRecordCount <
        eventDataCreatedByCount?.note_vw_wealth_events_createdby_aggregate
          ?.aggregate?.count &&
      eventDataCreatedByCount?.note_vw_wealth_events_createdby_aggregate
        ?.aggregate?.count > vars.InitRecordCount
    ) {
      setScrollPosition(scrollHeight)
      setFilterPayload((prev) => ({
        ...prev,
        offset: filterPayload.offset + vars.InitRecordCount,
      }))
    }
  }

  const [getEventsRepCode, { data: eventRepCode }] = useLazyQuery(
    GET_EVENTS_REPCODE_LIST,
    {
      variables: {
        ADUserName: user.username,
      },
      fetchPolicy: 'no-cache',
    }
  )

  const getTypeByTranType = (tranType: number) => {
    return typesData
      .filter((type: any) => type.trantype === tranType)
      .map((type: any) => {
        return { value: type.typeid, label: type.type }
      })
  }

  const getSubTypeByType = (typeId: number) => {
    return subTypesData
      .filter((type: any) => type.typeid === typeId && type.trantype === 3)
      .map((type: any) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }

  const getAllSubTypeByType = () => {
    return subTypesData
      .filter((type: any) => type.trantype === 3)
      .map((type: any) => {
        return { value: type.subtypeid, label: type.subtype }
      })
  }

  const getEventsRepcodes = () => {
    return eventRepCode?.note_vw_wealth_events_createdby
      ?.filter((item: any) => item.repcode)
      ?.map((rep: any) => ({ label: rep?.repcode, value: rep?.repcode }))
  }
  useEffect(() => {
    if (typesData) setAllTypes(getTypeByTranType(3))
    if (subTypesData) setSubTypes(getAllSubTypeByType())
    if (eventRepCode) setRepCodeData(getEventsRepcodes())
  }, [subTypesData, typesData, eventRepCode])

  useEffect(() => {
    if (
      eventData?.note_vw_wealth_events_createdby ||
      eventData?.note_vw_wealth_notes_book
    ) {
      const meetings =
        selectedTab == 'Created By'
          ? eventData?.note_vw_wealth_events_createdby
          : eventData?.note_vw_wealth_notes_book
      if (eventFilter.value === 'Upcoming Events') {
        const finalResponse = meetings.filter((meeting: any) =>
          meeting.starttime?.endsWith('Z')
            ? meeting.starttime >= new Date().toISOString()
            : `${meeting.starttime}Z` >= new Date().toISOString()
        )
        filterPayload?.offset > 0
          ? setEventContent((prev: any) => [...prev, ...finalResponse])
          : setEventContent(finalResponse)
      } else if (eventFilter.value === 'Past Due') {
        const finalResponse = meetings.filter((meeting: any) =>
          meeting.starttime?.endsWith('Z')
            ? meeting.starttime <= new Date().toISOString()
            : `${meeting.starttime}Z` <= new Date().toISOString()
        )
        filterPayload?.offset > 0
          ? setEventContent((prev: any) => [...prev, ...finalResponse])
          : setEventContent(finalResponse)
      } else {
        filterPayload?.offset > 0 && meetings?.length > 0
          ? setEventContent((prev: any) => [...prev, ...meetings])
          : setEventContent(meetings)
      }
    }
  }, [
    eventData?.note_vw_wealth_events_createdby,
    eventData?.note_vw_wealth_notes_book,
    eventFilter.value,
    selectedTab,
  ])

  const updateMeetingStatus = (statusType: string, tranId: number) => {
    updateMeeting({
      variables: {
        statusId: statusType == 'open' ? 0 : statusType == 'close' ? 1 : 2, // Use the updated list
        tranId: tranId,
      },
    }).then(() => {
      getEvent()
    })
  }

  useEffect(() => {
    resetTableFilter()
    getEvent()
    getEventsCreatedByCount()
    getEventsRepCode()
    getEventsCreatedByCount()
  }, [])

  const refreshData = () => {
    getEvent()
    getEventsCreatedByCount()
  }
  // const handleChangeType = (option: any) => {
  //   if (option) {
  //     if (option == -1) {
  //       setSubTypeArray(orgSubTypeArray)
  //     } else {
  //       const allTypes: any = []
  //       orgSubTypeArray?.map((item: any) => {
  //         if (
  //           option.toString().toLowerCase() ===
  //             item.type.toString().toLowerCase() ||
  //           item.type.toString().toLowerCase() == '-1'
  //         ) {
  //           allTypes.push(item)
  //         }
  //       })
  //       setSubTypeArray(allTypes)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   const filterType: any[] = []
  //   const filterSubType: any[] = []

  //   if (eventContent) {
  //     filterType.push({ value: '-1', label: 'All' })
  //     eventContent?.map((item: any) => {
  //       if (
  //         item.typedescription.toString().trim() != '' &&
  //         item.advisor != '--'
  //       )
  //         filterType.push({ value: item.typeid, label: item.typedescription })
  //     })

  //     //make unique list
  //     setOrgTypeArray(
  //       [...new Set(filterType.map((item) => item.value))].map((value) =>
  //         filterType.find((item) => item.value === value)
  //       )
  //     )

  //     filterSubType.push({ value: '-1', label: 'All', type: -1 })
  //     eventContent?.map((item: any) => {
  //       if (
  //         item.subtypedescription.toString().trim() != '' &&
  //         item.advisor != '--'
  //       )
  //         filterSubType.push({
  //           value: item.subtypeid,
  //           label: item.subtypedescription,
  //           type: item.typeid,
  //         })
  //     })

  //     //make unique list
  //     setOrgSubTypeArray(
  //       [...new Set(filterSubType.map((item) => item.value))].map((value) =>
  //         filterSubType.find((item) => item.value === value)
  //       )
  //     )

  //     //rep code
  //     const filterRepCode: any[] = []
  //     if (eventContent.length > 0) {
  //       filterRepCode.push({ value: -1, label: 'All' })
  //       eventContent.map((item: any) => {
  //         if (
  //           item.repcode != '' &&
  //           item.repcode != '--' &&
  //           item.repcode != null
  //         )
  //           filterRepCode.push({ value: item.repcode, label: item.repcode })
  //       })
  //     }
  //     //make unique list for rep code
  //     setFilterRepCodeData(
  //       [...new Set(filterRepCode.map((item) => item.value))].map((value) =>
  //         filterRepCode.find((item) => item.value === value)
  //       )
  //     )
  //   }
  // }, [eventContent])

  // useEffect(() => {
  //   setTypeArray(orgTypeArray)
  //   setSubTypeArray(orgSubTypeArray)
  // }, [orgSubTypeArray, orgTypeArray])

  return (
    <Wrapper>
      {/* <CommonTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
      <ContentItemWrapper>
        {eventContent?.length > 0 &&
          (loadingEdit || eventsLoadingCreatedBy) && (
            <Loader position="absolute" />
          )}
        <Table
          key={eventContent?.length}
          fetchData={fetchData}
          source="eventData"
          updateStatus={updateMeetingStatus}
          refetchInteraction={getEvent}
          allDataCount={
            eventDataCreatedByCount?.note_vw_wealth_events_createdby_aggregate
              ?.aggregate?.count ?? 0
          }
          tableProperties={{
            tableHeader: 'Events',
            globalSearchRequired: true,
            tileFilterRequired: true,
            tileFilterOptions:
              eventData &&
              (eventData?.note_vw_wealth_notes_book?.length ||
                eventData?.note_vw_wealth_events_createdby?.length)
                ? [
                    {
                      id: '1',
                      value: 'All Events',
                    },
                    { id: '2', value: 'Upcoming Events' },
                    { id: '3', value: 'Past Due' },
                  ]
                : [],
            tileFilterValue: eventFilter,
            tileFilterCallback: setEventFilter,
            paginatorRequired: true,
            headerButtonRequired: true,
            buttonLabel: 'New Event',
            createCallback: () => setNewEvents(true),
          }}
          isLoading={eventContent?.length == 0 && eventsLoadingCreatedBy}
          tableColumns={
            eventContent && eventContent?.length > 0 ? leadColumnData : []
          }
          allData={eventContent ?? []}
          filterData={summaryFilters}
          setSummaryFilters={setSummaryFilters}
          typeArray={allTypes}
          subTypeArray={subTypes}
          // handleChangeType={handleChangeType}
          repCodeList={repCodeData}
          sortingFields={[
            'subject',
            'partyname',
            'location',
            'typedescription',
            'subtypedescription',
            'starttime',
            'endtime',
            'ownername',
            'meetingstatusid',
            'repcode',
          ]}
        />
      </ContentItemWrapper>
      {showNewEvents && (
        <CreateEventComponent
          onTypeChange={(typeId: number) => {
            setSubTypes(getSubTypeByType(typeId))
          }}
          typeData={allTypes}
          subTypeData={subTypes}
          user={user}
          refetchContents={refreshData}
          closeEvent={setNewEvents}
          hostName={appConfig.apiEndPoint}
          accessToken={accessToken}
          combinedData={[...leadsDetails, ...partyDetails]}
          validationRequired={vars.validationRequired}
        />
      )}
    </Wrapper>
  )
}

export default Event
