import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import Glue from '@glue42/desktop'
import { Glue42DesktopFactory, GlueProvider } from '@glue42/react-hooks'
import GlueWorkspaces from '@glue42/workspaces-api'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { UserContextProvider } from './services/user/UserContext'
import { msalConfig } from './settings/authConfig'

export const msalInstance = new PublicClientApplication(msalConfig)

// const GlueContextProvider = () => (
//   <GlueProvider
//     fallback={<h1>Loading...</h1>}
//     settings={{
//       desktop: {
//         factory: Glue as Glue42DesktopFactory,
//         config: {
//           channels: true,
//           appManager: 'full',
//           layouts: 'full',
//           libraries: [GlueWorkspaces],
//         },
//       },
//     }}
//   >
//     <App />
//   </GlueProvider>
// )

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </MsalProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
