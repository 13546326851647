const appConfig = {
  envName: 'DEV',
  appName: 'CRM Web App',
  appDomain: 'https://wealthcrmui.dev.wedbush.com/',
  apiEndPoint: 'https://dev.api.wedbush.com',
  hasuraEndpoint: 'https://dev.api.wedbush.com/hasura-graphql',
  aadClientId: 'ccf1c3eb-4b7b-4062-bb9b-3a8d7d25449d',
  aadApiScope: 'api://wedbushapi-int-dev/api_access',
  aadAuthority: 'https://login.microsoftonline.com/296c5d32-91cc-45e5-bc0c-26df2ced0334',
  aadTenantId: '296c5d32-91cc-45e5-bc0c-26df2ced0334',
}

export default appConfig
