const ENDPOINTS = {
  GET_INDIVIDUAL_DETAILS_DATA:
    '/clientapi/IndividualParty/GetIndividualDetailsData',
  GET_PARTY_CONTACTS: '/clientapi/PartyContact/GetPartyContact',
  GET_CLIENT_ADDRESS: '/clientapi/ClientAddress/GetClientAddress',
  GET_CLIENT_EMAIL: '/clientapi/PartyContact/GetPartyEmail',
  GET_CLIENT_ACCOUNTS: '/clientapi/PartyInfo/GetClientAccounts',
  GET_CLIENT_LIST_BY_NAME: '/clientapi/Party/GetClientListByName',

  //File Transfer API
  FILE_DELETE: '/crmwealthnotesapi/TranDocuments/FileDelete',
  FILE_UPLOAD: '/crmwealthnotesapi/TranDocuments/FileUpload',
  FILE_DOWNLOAD: '/crmwealthnotesapi/TranDocuments/FileDownload',

  //Associate API
  GET_ASSOCIATE_BY_NAME: '/AssociatesAPI/Associates/GetAssociatesDetailsByName',
  GET_REP_ACCESS_LEVEL_BY_USER:
    '/advisormasterapi/VisibilityCheck/GetRepAccessLevelsByUserName',
  GET_AD_USER_BY_REP_CODE:
    '/advisormasterapi/VisibilityCheck/GetListOfADUserNamesByRepCode',
  //Entitlement API :
  GET_USER_ENTITLEMENT:
    '/advisormasterapi/commonauthorization/GetUserEntitlements',
}

export default ENDPOINTS
