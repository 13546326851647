import { useLazyQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import Table from '../../components/table/Table'
import { WDLabelPrimaryLarge } from '../../components/ui/WDLabel'
import { GET_LEADS_NOT_CONTACTED } from '../../services/graphQl/CustomerGraphQl'
import { ContentTitleWrapper } from '../../shared/GlobalStyle'
import { ItemsWrapper } from '../dashboardTask/style'
import { columnData, dashboardLeadsData } from './DashboardLeadsData'
import { LottieWrapperMedium } from '../loader/styles'
import Lottie from 'lottie-react'
import LottieLoader from '../../assets/lottiefiles/loader.json'
import UserContext from '../../services/user/UserContext'

const DashboardLeads = () => {
  const [listOfParties, setListOfParties] = useState<any[]>([])
  const { user } = useContext(UserContext)

  const getPastMonthDate = () => {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    return date
  }

  const [getLeadsNotContacted, { data: leadData, loading }] = useLazyQuery(
    GET_LEADS_NOT_CONTACTED
  )

  useEffect(() => {
    getLeadsNotContacted({
      variables: {
        userName: user.username,
      },
    })
  }, [getLeadsNotContacted, user.username])

  useEffect(() => {
    if (leadData) {
      const array: any = []
      leadData?.advisor_vw_leadpartyvisibility?.forEach((item: any) => {
        if (item.party_details) {
          array.push({
            name: item.party_details.PartyFullName,
            email: item.party_details.PartyEmail,
            phone: item.party_details.PartyContact,
            type: item.party_details.PartyTypeName,
            lastContacted: item?.leadNotContacted?.lastcontactdate ?? '--',
            status: 'Client',
            partyid: item.partyid,
          })
        } else if (item.lead_details) {
          array.push({
            name:
              item?.lead_details?.firstname +
              ' ' +
              item?.lead_details?.middlename +
              item?.lead_details?.lastname,
            email: item?.lead_details?.emailList.find(
              (email: any) => email.isprimary
            )?.emailid,
            phone: item?.lead_details?.contactList.find(
              (phone: any) => phone.isprimary
            )?.phonenumber,
            type: item?.lead_details?.type,
            lastContacted: item?.leadNotContacted?.lastcontactdate ?? '--',
            status: 'Lead',
            partyid: item.partyid,
          })
        }
      })
      const a = array.filter((ele: any) => ele.lastContacted == '--')
      const b = array.filter(
        (item: any) =>
          new Date(getPastMonthDate()) > new Date(item.lastContacted)
      )
      setListOfParties([...a, ...b])
    }
  }, [leadData])

  return (
    <>
      <ContentTitleWrapper>
        <WDLabelPrimaryLarge>
          {'Leads/Parties not contacted for last 30 days'}
        </WDLabelPrimaryLarge>
      </ContentTitleWrapper>
      <ItemsWrapper>
        {loading ? (
          <LottieWrapperMedium>
            <Lottie animationData={LottieLoader} loop={true} />
          </LottieWrapperMedium>
        ) : (
          <Table
            hasFooter={false}
            tableColumns={
              dashboardLeadsData && dashboardLeadsData?.length ? columnData : []
            }
            allData={
              listOfParties?.filter((ele: any) => ele?.name?.trim() != '') ?? []
            }
            source="NotContacted"
            isLoading={loading}
          />
        )}
      </ItemsWrapper>
    </>
  )
}

export default DashboardLeads
