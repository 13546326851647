import { useLazyQuery } from '@apollo/client'
import Lottie from 'lottie-react'
import { useContext, useEffect } from 'react'
import LottieLoader from '../../assets/lottiefiles/loader.json'
import Table from '../../components/table/Table'
import { WDLabelPrimaryLarge } from '../../components/ui/WDLabel'
import DashboardContext from '../../services/dashboardContext/DashboardContext'
import { GET_DASHBOARD_NOTES } from '../../services/graphQl/CustomerGraphQl'
import UserContext from '../../services/user/UserContext'
import { ContentTitleWrapper, FootWrapper } from '../../shared/GlobalStyle'
import { ItemsWrapper } from './style'
import { LottieWrapperMedium } from '../loader/styles'
import { WDButtonTransparent } from '../../components/ui/WDButton'
import Button from '../../components/button/Button'
import { DEFAULT_COLORS } from '../../theme/Colors'
import { useNavigate } from 'react-router-dom'

const DashboardNote = () => {
  const { user } = useContext(UserContext)
  const { refetchInteractionsFlag, setRefetchInteractionsFlag } =
    useContext(DashboardContext)

  const [getDashboardNotes, { data: noteData, loading: noteLoading }] =
    useLazyQuery(GET_DASHBOARD_NOTES, {
      variables: { limit: 10, offset: 0, userName: user.username },
      fetchPolicy: 'no-cache',
    })

  useEffect(() => {
    getDashboardNotes()
    setRefetchInteractionsFlag(false)
  }, [refetchInteractionsFlag])

  const navigate = useNavigate()

  const handleRoute = () => {
    navigate('/note')
  }
  const columnData = [
    {
      Header: 'Subject',
      accessor: 'subject',
    },
    {
      Header: 'Party/Lead',
      accessor: 'partyname',
    },
    {
      Header: 'Account',
      accessor: 'accountnumber',
    },
    {
      Header: 'Owner',
      accessor: 'ownername',
    },
  ]

  return (
    <>
      <ContentTitleWrapper>
        <WDLabelPrimaryLarge>{'Recent Notes'}</WDLabelPrimaryLarge>
      </ContentTitleWrapper>
      <ItemsWrapper>
        {noteLoading ? (
          <LottieWrapperMedium>
            <Lottie animationData={LottieLoader} loop={true} />
          </LottieWrapperMedium>
        ) : (
          <Table
            tableColumns={
              noteData &&
              noteData.note_vw_wealth_notes_createdby &&
              noteData.note_vw_wealth_notes_createdby.length > 0
                ? columnData
                : []
            }
            refetchInteraction={getDashboardNotes}
            allData={noteData?.note_vw_wealth_notes_createdby ?? []}
          />
        )}
      </ItemsWrapper>
      {noteData &&
        noteData.note_vw_wealth_notes_createdby &&
        noteData.note_vw_wealth_notes_createdby.length > 0 && (
          <FootWrapper>
            <WDButtonTransparent>
              <Button
                type="button"
                color={DEFAULT_COLORS.Background.Primary}
                onClick={handleRoute}
              >
                {'Click here for more items'}
              </Button>
            </WDButtonTransparent>
          </FootWrapper>
        )}
    </>
  )
}

export default DashboardNote
